<template>
  <v-container>

    <NavBar :step="0" />

    <Titulo></Titulo>

    <br>
    <br>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Mascota
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="step-data">

            <v-form ref="form" v-model="valid" max-witdh="300px">

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 26px;  letter-spacing: -0.01em; color: #002C77; mix-blend-mode: darken;">
                ¡Hola!</h3>
              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77;">
                ¿Cómo te llamas?</h3>
              <br>

              <v-row>
                <v-col>
                  <v-text-field v-model="userData.name" maxlength="51" :rules="nameRules" label="Nombre" required solo
                    filled @change="change()" style="box-sizing: border-box; height: 57px; margin:auto;
                          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                          border-radius: 6px;"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field v-model="userData.surname" maxlength="51" @change="change()" :rules="surnameRules"
                    label="Apellido" required solo filled style="box-sizing: border-box; height: 57px;
                          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); margin:auto;
                          border-radius: 6px;"></v-text-field>
                </v-col>

              </v-row>
              <br>
              <br>

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77;">
                ¿Cuál es tu email?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Así podremos enviarte tu cotización, descuentos y comunicaciones.</h4>

              <v-text-field v-model="userData.email" maxlength="51" @change="change()" :rules="emailRules"
                label="Ingresa tu correo electrónico" required solo filled style="box-sizing: border-box; height: 57px;  margin:auto;
                          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                          border-radius: 6px;"></v-text-field>
              <br>
              <br>

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77;">
                ¿Cuál es tu teléfono?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Así también podremos enviarte la información a tu celular.</h4>

              <v-row>

                <v-col class="text-center" style="display:flex; justify-content: center;">
                  <v-text-field v-model="prefijoTelefono" label="+569" disabled solo filled style="box-sizing: border-box; max-width: 65px; height: 57px;
                          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                          border-radius: 6px 0 0 6px; "></v-text-field>

                  <v-text-field v-model="userData.phoneNumber" maxlength="8" :counter="8" @change="change()"
                    :rules="phoneRules" label="000 000 0000" required solo filled style="box-sizing: border-box; height: 57px; 
                          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); 
                          border-radius:0 6px 6px 0; "></v-text-field>
                </v-col>

              </v-row>

              <br>
              <br>

              <!-- <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77;">
                ¿Cuál es tu edad?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 4%;">
                Es para recomendarte el mejor seguro y además
                saludarte el día de tu cumpleaños.</h4> -->

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                ¿Cuál es tu fecha de nacimiento?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Es para recomendarte el mejor seguro y además
                saludarte el día de tu cumpleaños.</h4>
              <div class="step-data">

                <v-row v-if="screen < 800">

                  <v-col>
                    <v-select :rules="monthRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="months" v-model="userData.month" :loading="loadingMonths"
                      v-on:change="getDays()" item-text="name" label="Mes" return-object required solo
                      filled></v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="dayRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="days" v-model="userData.day" :loading="loadingDays"
                      :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object required solo
                      filled>
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="yearRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" value="2022" :items="years" v-model="userData.year" item-text="name" label="Año"
                      return-object required solo filled @change="changeBisiesto()"></v-select>
                  </v-col>



                </v-row>

                <v-row v-else>

                  <v-col>
                    <v-select :rules="monthRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="months" v-model="userData.month" :loading="loadingMonths"
                      v-on:change="getDays()" item-text="name" label="Mes" return-object required solo
                      filled></v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="dayRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="days" v-model="userData.day" :loading="loadingDays"
                      :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object required solo
                      filled>
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="yearRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" value="2022" :items="years" v-model="userData.year" item-text="name" label="Año"
                      return-object required solo filled @change="changeBisiesto()"></v-select>
                  </v-col>

                </v-row>

                <v-row justify="center">
                  <v-dialog v-model="dialog" width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                      </v-card-title>
                      <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus modificaciones, autorizo expresamente a Southbridge Compañía de Seguros Generales S.A. para
                        que haga tratamiento, almacene, transmita y comunique mis datos personales y la información que
                        le he proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas, sociedades del Grupo Empresarial al que pertenece y terceros prestadores de
                        servicios, estén ubicados dentro o fuera de Chile, con el propósito de que tanto Southbridge
                        Compañía de Seguros Generales S.A. como dichas entidades i) me contacten y pongan a mi
                        disposición, en la forma que estimen pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi información personal para los objetivos del cumplimiento de este Contrato de Seguro; y iii)
                        hagan efectivos el o los convenios que pudieren estar asociados a los seguros que he contratado.
                        Además, por este acto, autorizo expresamente a que cualquier información, comunicación,
                        declaración o notificación que debe efectuar Southbridge Compañía de Seguros Generales S.A. en
                        virtud del presente contrato de seguros sea realizada a través de correo electrónico o de
                        cualquier sistema de transmisión o registro digital de la palabra escrita o verbal o por
                        cualquier otro medio de comunicación fehaciente. El asegurado declara que conoce, acepta y
                        concuerda que cualquier transacción que se realice en este sitio con su Rut, se entenderá y se
                        reputará, para todos los efectos legales, como realizada por él mismo. En tal sentido,
                        Southbridge Compañía de Seguros Generales S.A. no tiene ninguna responsabilidad por el uso
                        indebido de la información empleada por un tercero distinto del asegurado. El asegurado se hace
                        responsable por la veracidad de la información entregada para la contratación del seguro y del
                        uso de las claves o códigos, liberando a Southbridge Compañía de Seguros Generales S.A. de
                        responsabilidad por cualquier mal uso que hagan terceros de la información que Southbridge
                        Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del asegurable.

                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                          elevation="0" text @click="dialog = false">
                          Aceptar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>


                <v-hover v-if="screen > 800">
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323;
                                opacity: 0.8; text-align: justify; max-width: 400px; margin-top: 10%;">Al ingresar tus
                        datos
                        y
                        hacer
                        clic en continuar aceptas que tu información sea utilizada para calcular tu estimación de prima,
                        recomendar un seguro y
                        poder comunicarnos contigo de acuerdo a nuestros nuestros <b><a style="color:rgb(0, 44, 119);"
                            @click="dialog = true">Términos y Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>

                <v-hover v-else>
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323;
                                opacity: 0.8; text-align: justify; margin-top: 10%;">Al ingresar tus datos y hacer clic
                        en
                        continuar
                        aceptas que tu información sea utilizada para calcular tu estimación de prima, recomendar un
                        seguro y
                        poder comunicarnos contigo de acuerdo a nuestros nuestros <b><a style="color:rgb(0, 44, 119);"
                            @click="dialog = true">Términos y Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>

              </div>

              <div class="button-container">
                <v-btn style="background-color: #002C77; color: white; border-radius: 12px; width: 100%; height: 120%;"
                  @click="validate()">
                  Continuar
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

const moment = require('moment');

import swal from 'sweetalert';
import CryptoJS from 'crypto-js'

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    version: null,
    secretKey: process.env.VUE_APP_CRYPT,
    stepperColor: '#00968F',
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    dialog: false,
    screen: window.screen.width,
    months: [],
    days: ["Selecciona el mes"],
    yars: [],
    prefijoTelefono: null,
    benf: [
      "Individual",
      "Familiar",
    ],
    ss: [
      "Fonasa",
      "Isapre",
    ],
    valid: false,
    oldRules: [
      (v) => !!v || "La edad es requerida",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (v !== null && !(/^\s+$/.test(v)) && v.trim() !== '') || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (v !== null && !(/^\s+$/.test(v)) && v.trim() !== '') || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    benefRules: [(v) => !!v || "Seleccione Beneficiarios"],
    ssRules: [(v) => !!v || "Seleccione Sistema de Salud"],
    saludRules: [(v) => !!v || "Seleccione Una Opcion"],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ]

  }),
  async mounted() {
    this.getMonths();
    this.getYears();

    const query = this.$route.query;
    const userDataAux = localStorage.getItem('userData');
    const servicesDataAux = localStorage.getItem('servicesData');

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();

      if (this.userData.dates) {
        this.getDaysMounted();
      }

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);

    } else if (userDataAux) {
      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      if (this.userData.dates) {
        this.getDaysMounted();
      }

      if (servicesDataAux) {
        const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
        const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
        this.servicesData = JSON.parse(decryptedServicesData);
        // console.log("serivcesData: ", this.servicesData);
      }

    } else {
      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      localStorage.setItem('userData', encryptedUserDataLocal);
    }

  },
  methods: {
    async changeBisiesto() {
      if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && !this.isBisiesto(this.userData.year.id) && this.days.length == 29) {
        this.userData.day = null;
        this.days = await dateService.getDays(
          this.userData.month.name, false
        );
      } else if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && this.isBisiesto(this.userData.year.id) && this.days.length == 28) {
        this.days = await dateService.getDays(
          this.userData.month.name, true
        );
      } else {
        this.days = await dateService.getDays(
          this.userData.month.name, null
        );
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    validate() {
      const fechaActual = new moment(new Date());

      if (this.$refs.form.validate()) {

        var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
        var dateNacimiento = moment(new Date(newDate));
        var timesDiff = fechaActual.diff(dateNacimiento, 'years');

        if (timesDiff < 18) {

          swal({
            title: "Atención!",
            text: "Para contratar este seguro, usted debe ser mayor de edad!",
            icon: "info",
            button: "De acuerdo",
          });

        } else {

          this.userData.dates = newDate
          this.userData.old = timesDiff;

          this.change();

          const userDataLocal = JSON.stringify(this.userData);
          const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
          localStorage.setItem('userData', encryptedUserDataLocal);

          this.$router.push({ path: '/mascotas' });
        }
      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.userData.day = null;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getDaysMounted() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    prefijo() {
      this.prefijoTelefono = '+569'
    },
    async change() {

      try {

        if (this.userData.year && this.userData.month && this.userData.day) {
          const fechaActual = new Date();
          var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
          var diff = fechaActual.getFullYear() - this.userData.year.id
          if (diff > 18) {
            this.userData.dates = newDate;
          }
        }

        const servicesDataAux = localStorage.getItem('servicesData');

        if (servicesDataAux) {
          const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
          const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
          this.servicesData = JSON.parse(decryptedServicesData);
          // console.log("serivcesData: ", this.servicesData);
        }

        var carro = await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Inicio');
        this.servicesData.type = 'update';
        this.servicesData.stepId = carro.data.data._id;

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        localStorage.setItem('servicesData', encryptedServicesDataLocal);

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}


.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 500;
    text-align: center;
    color: #464e71;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 500;
    color: #464e71;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
  }

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {


      &:hover {
        opacity: 1;
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}


.v-btn__content {
  padding: 0.75rem;
}

.circle-container {
  // Boton de eliminar - circulo exterior
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1px solid #FFB600;
  margin-top: 30%;
}

.circle-button {
  // Boton de eliminar - circulo Helveticaior
  width: 30px;
  height: 30px;
  border-radius: 200px;
  background-color: #FFB600;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: auto;
}

.circle-button v-icon {
  // Boton de eliminar - Medidas del minus
  width: 7px;
  height: 14px;
}

@media only screen and (max-width: 959.98px) {

  // Evita el mecanismo de esconder las letras del steper
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

.primary-override .v-application .primary {
  // Aqui sobreescribe el color del primary
  background-color: #FFB600 !important;
  border-color: #FFB600 !important;
}

.v-stepper__step__step {
  // Este es el step y sus medidas
  height: 61px !important;
  width: 61px !important;
  font-size: 24px !important;
  margin-top: 25%;
  background-size: cover;
  background-image: url("~@/assets/images/step2.jpg");
}

@media only screen and (max-width: 767px) {
  .v-stepper__step__step {
    width: 42px !important;
    height: 42px !important;
    font-size: 20px !important;
    margin-top: 40%
  }
}

.v-stepper__header {
  // Stepper header - contenedor de steper completo
  box-shadow: none !important;
  max-width: 70%;
  align-items: center !important;
  margin: auto !important;
  flex-wrap: nowrap !important;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  // boton apagado
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #8E8E8E;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.v-stepper__label {
  padding: 10%;
}

@media only screen and (max-width: 767px) {
  .v-stepper__header {
    // Stepper header - contenedor de steper completo
    box-shadow: none !important;
    max-width: 100%;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 15px; // el ancho
    position: relative;
  }
}



html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a.router-link-exact-active.router-link-active div button.v-icon.notranslate.v-icon--link.mdi.mdi-chevron-left.theme--light {
  color: #002C77 !important;
}
</style>