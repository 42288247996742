import dateData from "../utils/date-data";

export default class dateService {
  static async getMonths() {
    try {
      let months = dateData;
      return months;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los meses",
      };
    }
  }

  static async getDays(dateId, isBisiesto) {
    try {
      let months;
      if (typeof dateId === "string" || dateId instanceof String) {
        months = dateData.find((element) => element.name === dateId);
      } else if (!isNaN(parseFloat(dateId)) && !isNaN(dateId - 0)) {
        months = dateData.find((element) => element.number === dateId);
      } else {
        return [];
      }

      var days = months.days ? months.days : [];

      if (isBisiesto != null && isBisiesto == false) {
        days.pop();
      } else if (isBisiesto != null && isBisiesto == true) {
        days.push({ id: '29', name: '29' });
      }

      return days;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener los días",
      };
    }
  }
}