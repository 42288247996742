import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async newCreateStep(userData, petData, planData, servicesData, step) {
    try {

      let url;
      const jsonData = { userData, petData, planData, servicesData, step, sponsor: VUE_APP_SPONSOR };

      if (servicesData.type == 'create') {
        url = '/step/newCreate';
      } else if (servicesData.type == 'update') {
        url = '/step/newUpdate';
      }

      const carroResponse = await axios.post(
        `${VUE_APP_SHIELD_URL}${url}`,
        jsonData
      );

      return carroResponse;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error en el servicio Stepers",
      };
    }
  }

  static async newGetStepAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/newGetAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async getPlans(code, age, unit) {
    if (unit === "M" && age < 10) {
      age = 0
    }
    else if (unit === "M" && age >= 10) {
      // console.log("mes y mayor o igual a 10")
      age = 0
    }
    else if (unit === "Y" && age < 10) {
      // console.log("año y menor q 10")
      age = 0
      // console.log("aca123")
    }
    else if (unit === "Y" && age >= 10) {
      // console.log("año y mayor  o igual q 10")
      // console.log(age)
    }
    try {
      var url = `${VUE_APP_SHIELD_URL}/gallagher`;
      var params = "";
      if (code) {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&code=${code}&age=${age}`;
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&age=${age}`;
      }

      const shieldRes = await axios.get(
        params
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, pet, plan, stepId) {

    const jsonData = { person, pet, planId: plan._id, stepId: stepId };
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher/createWithRunWayPayment`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }

  static async createInsuranceWithToku(person, pet, plan, stepId) {

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    const jsonData = { person, pet, planId: plan._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }
}
