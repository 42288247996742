export default [
  {
    "name": "Mestizo",
    "id": ""
  },
  {
    "name": "Affenpinscher",
    "id": "D1"
  },
  {
    "name": "Airedale terrier",
    "id": "D2"
  },
  {
    "name": "Alano",
    "id": "D3"
  },
  {
    "name": "Alaskan Husky",
    "id": "D4"
  },
  {
    "name": "Alaskan malamute",
    "id": "D5"
  },
  {
    "name": "American Foxhound",
    "id": "D6"
  },
  {
    "name": "American Water Spaniel",
    "id": "D7"
  },
  {
    "name": "Antiguo Pastor Inglés",
    "id": "D8"
  },
  {
    "name": "Ariegeois",
    "id": "D9"
  },
  {
    "name": "Artois",
    "id": "D10"
  },
  {
    "name": "Australian silky terrier",
    "id": "D11"
  },
  {
    "name": "Australian Terrier",
    "id": "D12"
  },
  {
    "name": "Austrian Black and Tan Hound",
    "id": "D13"
  },
  {
    "name": "Azawakh",
    "id": "D14"
  },
  {
    "name": "Balkan Hound",
    "id": "D15"
  },
  {
    "name": "Basenji",
    "id": "D16"
  },
  {
    "name": "Basset Alpino (Alpine Dachsbracke)",
    "id": "D17"
  },
  {
    "name": "Basset Artesiano Normando",
    "id": "D18"
  },
  {
    "name": "Basset Azul de Gascuña (Basset Bleu de Gascogne)",
    "id": "D19"
  },
  {
    "name": "Basset de Artois",
    "id": "D20"
  },
  {
    "name": "Basset de Westphalie",
    "id": "D21"
  },
  {
    "name": "Basset Hound",
    "id": "D22"
  },
  {
    "name": "Basset Leonado de Bretaña (Basset fauve de Bretagne)",
    "id": "D23"
  },
  {
    "name": "Bavarian Mountain Scenthound",
    "id": "D24"
  },
  {
    "name": "Beagle",
    "id": "D25"
  },
  {
    "name": "Beagle Harrier",
    "id": "D26"
  },
  {
    "name": "Bearded Collie",
    "id": "D27"
  },
  {
    "name": "Beauceron",
    "id": "D28"
  },
  {
    "name": "Bedlington Terrier",
    "id": "D29"
  },
  {
    "name": "Bichon Boloñes",
    "id": "D30"
  },
  {
    "name": "Bichón Frisé",
    "id": "D31"
  },
  {
    "name": "Bichón Habanero",
    "id": "D32"
  },
  {
    "name": "Bichón Maltés",
    "id": "D33"
  },
  {
    "name": "Billy",
    "id": "D34"
  },
  {
    "name": "Black and Tan Coonhound",
    "id": "D35"
  },
  {
    "name": "Bloodhound (Sabueso de San Huberto)",
    "id": "D36"
  },
  {
    "name": "Bobtail",
    "id": "D37"
  },
  {
    "name": "Boerboel",
    "id": "D38"
  },
  {
    "name": "Border Collie",
    "id": "D39"
  },
  {
    "name": "Border terrier",
    "id": "D40"
  },
  {
    "name": "Boston terrier",
    "id": "D43"
  },
  {
    "name": "Bouvier des Flandres",
    "id": "D44"
  },
  {
    "name": "Boxer",
    "id": "D45"
  },
  {
    "name": "Boyero de Appenzell",
    "id": "D46"
  },
  {
    "name": "Boyero de Australia",
    "id": "D47"
  },
  {
    "name": "Boyero de Berna",
    "id": "D48"
  },
  {
    "name": "Boyero de Entlebuch",
    "id": "D49"
  },
  {
    "name": "Boyero de las Ardenas",
    "id": "D50"
  },
  {
    "name": "Boyero de Montaña Bernes",
    "id": "D51"
  },
  {
    "name": "Braco Alemán de pelo corto",
    "id": "D52"
  },
  {
    "name": "Braco Alemán de pelo duro",
    "id": "D53"
  },
  {
    "name": "Braco de Ariege",
    "id": "D54"
  },
  {
    "name": "Braco de Auvernia",
    "id": "D55"
  },
  {
    "name": "Braco de Bourbonnais",
    "id": "D56"
  },
  {
    "name": "Braco de Saint Germain",
    "id": "D57"
  },
  {
    "name": "Braco Dupuy",
    "id": "D58"
  },
  {
    "name": "Braco Francés",
    "id": "D59"
  },
  {
    name: "Braco Italiano",
    id: "D60",
  },
  {
    name: "Broholmer",
    id: "D61",
  },
  {
    name: "Buhund Noruego",
    id: "D62",
  },
  {
    name: "Bulldog americano",
    id: "D63",
  },
  {
    name: "Bulldog francés",
    id: "D64",
  },
  {
    name: "Bulldog inglés",
    id: "D65",
  },
  {
    name: "Ca de Bestiar",
    id: "D66",
  },
  {
    name: "Cairn terrier",
    id: "D67",
  },
  {
    name: "Cane Corso",
    id: "D68",
  },
  {
    name: "Cane da Pastore Maremmano-Abruzzese",
    id: "D69",
  },
  {
    name: "Caniche",
    id: "D70",
  },
  {
    name: "Caniche (Poodle)",
    id: "D71",
  },
  {
    name: "Caniche Toy (Toy Poodle)",
    id: "D72",
  },
  {
    name: "Cao da Serra de Aires",
    id: "D73",
  },
  {
    name: "Cao da Serra de Estrela",
    id: "D74",
  },
  {
    name: "Cao de Castro Laboreiro",
    id: "D75",
  },
  {
    name: "Cao de Fila de Sao Miguel",
    id: "D76",
  },
  {
    name: "Carlino",
    id: "D77",
  },
  {
    name: "Cavalier King Charles Spaniel",
    id: "D78",
  },
  {
    name: "Cesky Fousek",
    id: "D79",
  },
  {
    name: "Cesky Terrier",
    id: "D80",
  },
  {
    name: "Chesapeake Bay Retriever",
    id: "D81",
  },
  {
    name: "Chihuahua",
    id: "D82",
  },
  {
    name: "Chin",
    id: "D83",
  },
  {
    name: "Chow Chow",
    id: "D84",
  },
  {
    name: "Cirneco del Etna",
    id: "D85",
  },
  {
    name: "Clumber Spaniel",
    id: "D86",
  },
  {
    name: "Cocker Spaniel Americano",
    id: "D87",
  },
  {
    name: "Cocker Spaniel Inglés",
    id: "D88",
  },
  {
    name: "Collie Barbudo",
    id: "D89",
  },
  {
    name: "Collie de Pelo Corto",
    id: "D90",
  },
  {
    name: "Collie de Pelo Largo",
    id: "D91",
  },
  {
    name: "Cotón de Tuléar",
    id: "D92",
  },
  {
    name: "Curly Coated Retriever",
    id: "D93",
  },
  {
    name: "Dálmata",
    id: "D94",
  },
  {
    name: "Dandie Dinmont Terrier",
    id: "D95",
  },
  {
    name: "Deerhound",
    id: "D96",
  },
  {
    name: "Drentse Partridge Dog",
    id: "D97",
  },
  {
    name: "Drever",
    id: "D98",
  },
  {
    name: "Dunker",
    id: "D99",
  },
  {
    name: "Elkhound Noruego",
    id: "D100",
  },
  {
    name: "Elkhound Sueco",
    id: "D101",
  },
  {
    name: "English Foxhound",
    id: "D102",
  },
  {
    name: "English Springer Spaniel",
    id: "D103",
  },
  {
    name: "English Toy Terrier",
    id: "D104",
  },
  {
    name: "Epagneul Picard",
    id: "D105",
  },
  {
    name: "Eurasier",
    id: "D106",
  },
  {
    name: "Finlandés",
    id: "D107",
  },
  {
    name: "Finnish Lapphound",
    id: "D108",
  },
  {
    name: "Flat Coated Retriever",
    id: "D109",
  },
  {
    name: "Fox Terrier",
    id: "D110",
  },
  {
    name: "Fox Terrier de pelo de alambre",
    id: "D111",
  },
  {
    name: "Fox Terrier de pelo liso",
    id: "D112",
  },
  {
    name: "Foxhound Americano",
    id: "D113",
  },
  {
    name: "Foxhound Inglés",
    id: "D114",
  },
  {
    name: "Frisian Pointer",
    id: "D115",
  },
  {
    name: "Galgo Afgano",
    id: "D116",
  },
  {
    name: "Galgo Español",
    id: "D117",
  },
  {
    name: "Galgo Húngaro (Magyar Agar)",
    id: "D118",
  },
  {
    name: "Galgo Italiano",
    id: "D119",
  },
  {
    name: "Galgo Polaco (Chart Polski)",
    id: "D120",
  },
  {
    name: "Gigante de los Pirineos",
    id: "D121",
  },
  {
    name: "Glen of Imaal Terrier",
    id: "D122",
  },
  {
    name: "Golden Retriever",
    id: "D123",
  },
  {
    name: "Gordon Setter",
    id: "D124",
  },
  {
    name: "Gos d'Atura",
    id: "D125",
  },
  {
    name: "Gos d'Atura Catalá",
    id: "D126",
  },
  {
    name: "Gran Basset Griffon Vendeano",
    id: "D127",
  },
  {
    name: "Gran Boyero Suizo",
    id: "D128",
  },
  {
    name: "Gran Danés",
    id: "D129",
  },
  {
    name: "Gran Danés (Dogo Alemán)",
    id: "D130",
  },
  {
    name: "Gran Gascón Saintongeois",
    id: "D131",
  },
  {
    name: "Gran Griffon Vendeano",
    id: "D132",
  },
  {
    name: "Gran Munsterlander",
    id: "D133",
  },
  {
    name: "Gran Perro Japonés",
    id: "D134",
  },
  {
    name: "Grand Anglo Francais Tricoleur",
    id: "D135",
  },
  {
    name: "Grand Bleu de Gascogne",
    id: "D136",
  },
  {
    name: "Greyhound",
    id: "D137",
  },
  {
    name: "Griffon Bleu de Gascogne",
    id: "D138",
  },
  {
    name: "Griffon de pelo duro (Grifón Korthals)",
    id: "D139",
  },
  {
    name: "Griffon leonado de Bretaña",
    id: "D140",
  },
  {
    name: "Griffon Nivernais",
    id: "D141",
  },
  {
    name: "Grifon Belga",
    id: "D142",
  },
  {
    name: "Grifón de Bruselas",
    id: "D143",
  },
  {
    name: "Haldenstoever",
    id: "D144",
  },
  {
    name: "Harrier",
    id: "D145",
  },
  {
    name: "Hokkaido",
    id: "D146",
  },
  {
    name: "Hovawart",
    id: "D147",
  },
  {
    name: "Husky Siberiano",
    id: "D148",
  },
  {
    name: "Husky Siberiano (Siberian Husky)",
    id: "D149",
  },
  {
    name: "Ioujnorousskaia Ovtcharka",
    id: "D150",
  },
  {
    name: "Irish Glen of Imaal terrier",
    id: "D151",
  },
  {
    name: "Irish soft coated wheaten terrier",
    id: "D152",
  },
  {
    name: "Irish terrier",
    id: "D153",
  },
  {
    name: "Irish Water Spaniel",
    id: "D154",
  },
  {
    name: "Irish Wolfhound",
    id: "D155",
  },
  {
    name: "Jack Russell terrier",
    id: "D156",
  },
  {
    name: "Jindo Coreano",
    id: "D157",
  },
  {
    name: "Kai",
    id: "D158",
  },
  {
    name: "Keeshond",
    id: "D159",
  },
  {
    name: "Kelpie australiano (Australian kelpie)",
    id: "D160",
  },
  {
    name: "Kerry blue terrier",
    id: "D161",
  },
  {
    name: "King Charles Spaniel",
    id: "D162",
  },
  {
    name: "Kishu",
    id: "D163",
  },
  {
    name: "Komondor",
    id: "D164",
  },
  {
    name: "Kooiker",
    id: "D165",
  },
  {
    name: "Kromfohrländer",
    id: "D166",
  },
  {
    name: "Kuvasz",
    id: "D167",
  },
  {
    name: "Labrador Retriever",
    id: "D168",
  },
  {
    name: "Lagotto Romagnolo",
    id: "D169",
  },
  {
    name: "Laika de Siberia Occidental",
    id: "D170",
  },
  {
    name: "Laika de Siberia Oriental",
    id: "D171",
  },
  {
    name: "Laika Ruso Europeo",
    id: "D172",
  },
  {
    name: "Laika Ruso-europeo",
    id: "D173",
  },
  {
    name: "Lakeland terrier",
    id: "D174",
  },
  {
    name: "Landseer",
    id: "D175",
  },
  {
    name: "Lapphund Sueco",
    id: "D176",
  },
  {
    name: "Lebrel Afgano",
    id: "D177",
  },
  {
    name: "Lebrel Árabe (Sloughi)",
    id: "D178",
  },
  {
    name: "Leonberger",
    id: "D179",
  },
  {
    name: "Lhasa Apso",
    id: "D180",
  },
  {
    name: "Lowchen (Pequeño Perro León)",
    id: "D181",
  },
  {
    name: "Lundehund Noruego",
    id: "D182",
  },
  {
    name: "Malamute de Alaska",
    id: "D183",
  },
  {
    name: "Maltés",
    id: "D184",
  },
  {
    name: "Manchester terrier",
    id: "D185",
  },
  {
    name: "Mastiff",
    id: "D186",
  },
  {
    name: "Mastín de los Pirineos",
    id: "D187",
  },
  {
    name: "Mastín Español",
    id: "D188",
  },
  {
    name: "Mudi",
    id: "D189",
  },
  {
    name: "Norfolk terrier",
    id: "D190",
  },
  {
    name: "Norwich terrier",
    id: "D191",
  },
  {
    name: "Nova Scotia duck tolling retriever",
    id: "D192",
  },
  {
    name: "Otterhound",
    id: "D193",
  },
  {
    name: "Ovejero alemán",
    id: "D194",
  },
  {
    name: "Pastor Alemán",
    id: "D195",
  },
  {
    name: "Pastor Australiano",
    id: "D196",
  },
  {
    name: "Pastor Belga",
    id: "D197",
  },
  {
    name: "Pastor de Brie",
    id: "D198",
  },
  {
    name: "Pastor de los Pirineos de Cara Rosa",
    id: "D199",
  },
  {
    name: "Pekinés",
    id: "D200",
  },
  {
    name: "Perdiguero Chesapeake Bay",
    id: "D201",
  },
  {
    name: "Perdiguero de Drentse",
    id: "D202",
  },
  {
    name: "Perdiguero de Pelo lido",
    id: "D203",
  },
  {
    name: "Perdiguero de pelo rizado",
    id: "D204",
  },
  {
    name: "Perdiguero Portugués",
    id: "D205",
  },
  {
    name: "Podenco Ibicenco",
    id: "D206",
  },
  {
    name: "Podenco Portugués",
    id: "D207",
  },
  {
    name: "Presa Canario",
    id: "D208",
  },
  {
    name: "Presa Mallorquin",
    id: "D209",
  },
  {
    name: "Rafeiro do Alentejo",
    id: "D210",
  },
  {
    name: "Ratonero Bodeguero Andaluz",
    id: "D211",
  },
  {
    name: "Retriever de Nueva Escocia",
    id: "D212",
  },
  {
    name: "Rhodesian Ridgeback",
    id: "D213",
  },
  {
    name: "Ridgeback de Tailandia",
    id: "D214",
  },
  {
    name: "Rough Collie",
    id: "D215",
  },
  {
    name: "Saarloos",
    id: "D216",
  },
  {
    name: "Sabueso de Hamilton",
    id: "D217",
  },
  {
    name: "Sabueso de Hannover",
    id: "D218",
  },
  {
    name: "Sabueso de Hygen",
    id: "D219",
  },
  {
    name: "Sabueso de Istria",
    id: "D220",
  },
  {
    name: "Sabueso de Posavaz",
    id: "D221",
  },
  {
    name: "Sabueso de Schiller (Schillerstovare)",
    id: "D222",
  },
  {
    name: "Sabueso de Smaland (Smalandsstovare)",
    id: "D223",
  },
  {
    name: "Sabueso de Transilvania",
    id: "D224",
  },
  {
    name: "Sabueso del Tirol",
    id: "D225",
  },
  {
    name: "Sabueso Español",
    id: "D226",
  },
  {
    name: "Sabueso Estirio de pelo duro",
    id: "D227",
  },
  {
    name: "Sabueso Finlandés",
    id: "D228",
  },
  {
    name: "Sabueso Francés blanco y negro",
    id: "D229",
  },
  {
    name: "Sabueso Francés tricolor",
    id: "D230",
  },
  {
    name: "Sabueso Griego",
    id: "D231",
  },
  {
    name: "Sabueso Hélenico",
    id: "D232",
  },
  {
    name: "Sabueso Italiano",
    id: "D233",
  },
  {
    name: "Sabueso Polaco (Ogar Polski)",
    id: "D234",
  },
  {
    name: "Sabueso Serbio",
    id: "D235",
  },
  {
    name: "Sabueso Suizo",
    id: "D236",
  },
  {
    name: "Sabueso Yugoslavo de Montaña",
    id: "D237",
  },
  {
    name: "Sabueso Yugoslavo tricolor",
    id: "D238",
  },
  {
    name: "Saluki",
    id: "D239",
  },
  {
    name: "Samoyedo",
    id: "D240",
  },
  {
    name: "San Bernardo",
    id: "D241",
  },
  {
    name: "Sarplaninac",
    id: "D242",
  },
  {
    name: "Schapendoes",
    id: "D243",
  },
  {
    name: "Schipperke",
    id: "D244",
  },
  {
    name: "Schnauzer estándar",
    id: "D245",
  },
  {
    name: "Schnauzer gigante (Riesenschnauzer)",
    id: "D246",
  },
  {
    name: "Schnauzer miniatura (Zwergschnauzer)",
    id: "D247",
  },
  {
    name: "Scottish terrier",
    id: "D248",
  },
  {
    name: "Sealyham terrier",
    id: "D249",
  },
  {
    name: "Segugio Italiano",
    id: "D250",
  },
  {
    name: "Seppala Siberiano",
    id: "D251",
  },
  {
    name: "Setter Inglés",
    id: "D252",
  },
  {
    name: "Setter Irlandés",
    id: "D253",
  },
  {
    name: "Setter Irlandés rojo y blanco",
    id: "D254",
  },
  {
    name: "Shar Pei",
    id: "D255",
  },
  {
    name: "Shiba Inu",
    id: "D256",
  },
  {
    name: "Shih Tzu",
    id: "D257",
  },
  {
    name: "Shikoku",
    id: "D258",
  },
  {
    name: "Siberian Husky",
    id: "D259",
  },
  {
    name: "Skye terrier",
    id: "D260",
  },
  {
    name: "Slovensky Cuvac",
    id: "D261",
  },
  {
    name: "Slovensky Kopov",
    id: "D262",
  },
  {
    name: "Smoushond Holandés",
    id: "D263",
  },
  {
    name: "Spaniel Alemán (German Wachtelhund)",
    id: "D264",
  },
  {
    name: "Spaniel Azul de Picardía",
    id: "D265",
  },
  {
    name: "Spaniel Bretón",
    id: "D266",
  },
  {
    name: "Spaniel de Campo",
    id: "D267",
  },
  {
    name: "Spaniel de Pont Audemer",
    id: "D268",
  },
  {
    name: "Spaniel Francés",
    id: "D269",
  },
  {
    name: "Spaniel Tibetano",
    id: "D270",
  },
  {
    name: "Spinone Italiano",
    id: "D271",
  },
  {
    name: "Spitz Alemán",
    id: "D272",
  },
  {
    name: "Spitz de Norbotten (Norbottenspets)",
    id: "D273",
  },
  {
    name: "Spitz Finlandés",
    id: "D274",
  },
  {
    name: "Spitz Japonés",
    id: "D275",
  },
  {
    name: "Sussex Spaniel",
    id: "D276",
  },
  {
    name: "Tchuvatch eslovaco",
    id: "D277",
  },
  {
    name: "Teckel",
    id: "D278",
  },
  {
    name: "Teckel (Dachshund)",
    id: "D279",
  },
  {
    name: "Terranova",
    id: "D280",
  },
  {
    name: "Terranova (Newfoundland)",
    id: "D281",
  },
  {
    name: "Terrier australiano (Australian terrier)",
    id: "D282",
  },
  {
    name: "Terrier brasilero",
    id: "D283",
  },
  {
    name: "Terrier cazador alemán",
    id: "D284",
  },
  {
    name: "Terrier checo (Ceský teriér)",
    id: "D285",
  },
  {
    name: "Terrier Chileno",
    id: "D286",
  },
  {
    name: "Terrier galés",
    id: "D287",
  },
  {
    name: "Terrier irlandés (Irish terrier)",
    id: "D288",
  },
  {
    name: "Terrier japonés (Nihon teria)",
    id: "D289",
  },
  {
    name: "Terrier negro ruso",
    id: "D290",
  },
  {
    name: "Terrier tibetano",
    id: "D291",
  },
  {
    name: "Viejo Pastor Inglés",
    id: "D292",
  },
  {
    name: "Viejo Pointer Danés (Old Danish Pointer)",
    id: "D293",
  },
  {
    name: "Vizsla",
    id: "D294",
  },
  {
    name: "Volpino Italiano",
    id: "D295",
  },
  {
    name: "Weimaraner",
    id: "D296",
  },
  {
    name: "Welsh Corgi Cardigan",
    id: "D297",
  },
  {
    name: "Welsh Corgi Pembroke",
    id: "D298",
  },
  {
    name: "Welsh springer spaniel",
    id: "D299",
  },
  {
    name: "Welsh terrier",
    id: "D300",
  },
  {
    name: "West highland white terrier",
    id: "D301",
  },
  {
    name: "Whippet",
    id: "D302",
  },
  {
    name: "Wirehaired solvakian pointer",
    id: "D303",
  },
  {
    name: "Xoloitzcuintle",
    id: "D304",
  },
  {
    name: "Yorkshire Terrier",
    id: "D305",
  }
];
