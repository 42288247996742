<template>
  <v-container>

    <NavBar :step="3" />

    <Titulo></Titulo>



    <br>
    <br>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Mascota
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">
                  <br>
                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77; margin-top: -5%;">
                    Ingresa tus datos</h3>
                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Son necesarios para completar la póliza del seguro</h4>

                  <br>

                  <v-text-field v-model="userData.name" maxlength="51" @change="change()" :rules="nameRules"
                    label="Nombre" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.surname" maxlength="51" @change="change()" :rules="surnameRules"
                    label="Apellido" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.email" maxlength="51" @change="change()" :rules="emailRules"
                    label="Email" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.phoneNumber" maxlength="8" :counter="8" @change="change()"
                    :rules="phoneRules" prefix="+569" label="Teléfono" required solo filled
                    class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model.lazy="newRut" maxlength="12" @change="change()" :rules="rutRules"
                    label="Ingresa tu RUT ( sin punto y con guión)" required solo filled class="v-textField"
                    @input="changeRut"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.serialNumber" maxlength="10" @change="change()"
                    :rules="serialNumberRules" label="Ingresa el número de Documento / Serie" required solo filled
                    class="v-textField">
                  </v-text-field>

                  <br>

                  <!-- <v-text-field v-model="userData.address" maxlength="51" @change="change()" :rules="addressRules"
                    label="Ingresa tu dirección" required  solo filled class="v-textField" ></v-text-field> -->

                  <v-text-field v-model="userData.address" @change="change()" :rules="addressRules" maxlength="51"
                    label="Ingresar nombre de calle" required solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
                  background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                  border-radius: 6px;"></v-text-field>

                  <br>

                  <div class="number-address">
                    <v-text-field class="labelsDatos-address" v-model="userData.addressNumber"
                      :rules="NumberRulesAddress" maxlength="51" :label="addressNumber" required filled solo
                      @change="change()"></v-text-field>
                    <v-text-field class="labelsDatos-address" v-model="userData.addressDepto"
                      :rules="NumberRulesAddress2" maxlength="51" :label="addressDepto" filled solo
                      @change="change()"></v-text-field>

                  </div>

                  <br>

                  <v-select :items="regions" v-model="userData.region" @change="change()" :rules="regionRules"
                    :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object solo filled
                    class="v-textField"></v-select>

                  <br>

                  <v-select :items="communes" v-model="userData.commune" :rules="communeRules" @change="change()"
                    :loading="loadingCommunes" :disabled="loadingCommunes || userData.region === null" item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object solo filled class="v-textField">
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>
                  </div>

                </v-col>

              </div>

              <!-- <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn text @click="back()"> Volver </v-btn>
      </v-row> -->
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import RutUtils from "@/utils/rut.utils.js";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import CryptoJS from 'crypto-js';

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    valid: false,
    stepperColor: '#00968F',
    screen: window.screen.width,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      stepId: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'update',
    },
    petData: {
      species: null,
      breed: null,
      breedCode: null,
      petName: null,
      age: null,
      sex: null,
      hasMicrochip: false,
    },
    planData: {
      couponId: null,
      plan: null,
    },
    
    e1: 4,

    months: [],
    days: [],
    yars: [],

    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/.test(v) || "Ingrese solo números y letras"
    ],
    regionRules: [(v) => !!v || "Ingresa la region en la que vives con tu mascota",],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives",],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
      (v) => /^[0-9kK.-]+$/.test(v) || 'No se permiten carácteres especiales',
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
    ],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    NumberRulesAddress: [
      (v) => !!v || "N° de calle requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    NumberRulesAddress2: [
      (v) => !v || /^[a-zA-Z0-9]+$/.test(v) || "Ingrese solo números y letras"
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    regions: [],
    communes: [],
    newRut: "",
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
  }),
  async mounted() {

    this.getRegions();

    const query = this.$route.query;

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.petData = abandoned.petData;
      this.planData = abandoned.planData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const petDataLocal = JSON.stringify(this.petData);
      const planDataLocal = JSON.stringify(this.planData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('petData', encryptedPetDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);

      this.getCommunes();

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
      }

    } else {
      const userDataAux = localStorage.getItem('userData');
      const petDataAux = localStorage.getItem('petData');
      const servicesDataAux = localStorage.getItem('servicesData');
      const planDataAux = localStorage.getItem('planData');

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);

      this.getCommunes();

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
      }
      // console.log("userData: ", this.userData);

      const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
      const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
      this.petData = JSON.parse(decryptedDataPet);
      // console.log("petData: ", this.petData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedDataPlan);
      // console.log("planData: ", this.planData);
    }

    if (this.screen > 800) {
      this.vista = 'vista2'
      this.TipoPlan = 'PlanEscritorio'
      this.addressNumber = 'Ingresa tu número de calle'
      this.addressDepto = 'Ingresa tu número de depto'
    } else {
      this.vista = 'vista'
      this.TipoPlan = 'PlanMovil'
      this.addressNumber = 'Número de calle'
      this.addressDepto = 'Número de depto'
    }
  },
  methods: {
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        // this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    async validate() {
      if (this.$refs.form.validate()) {

        const userDataLocal = JSON.stringify(this.userData);
        const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
        localStorage.setItem('userData', encryptedUserDataLocal);

        this.$router.push({ path: '/resumen' });
      }
    },
    async change() {

      try {

        await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Datos personales');

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

.number-address {
  display: flex;
  gap: 10px;
}

.labelsDatos-address {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #002C77 !important;
}

.v-textField {
  box-sizing: border-box;
  height: 48px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}
</style>